import React from 'react';

const ContactsEn = () => {
    // 定义统一的样式对象
    const itemStyle = {
        marginLeft: '5rem', 
    };

    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a href="mailto:company@domain.com">Laydeez_skin</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>Wechat</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>shop 1 / 19 Burwood Road Burwood NSW </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>Address</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a href="tel:005053255350539">0272089381</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>Phone</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a href="tel:005053255350539">17RileyLn,BurwoodNSW,2134</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>Parking address</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactsEn;