import React, { Component } from 'react';
import Swiper from 'swiper';

class ServicesHome extends Component {
    componentDidMount() {
        this.swiper = new Swiper('.adv-slider-services .adv-swiper-container', {
            autoplay: {
                delay: 3000, // 自动切换的时间间隔，单位为毫秒

                disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
            },
            loop:true,
            speed: 2000,
            // ADV swiper options
            noSwipingClass: 'adv-swiper-no-swiping',
            containerModifierClass: 'adv-swiper-container-',
            slideClass: 'adv-swiper-slide',
            slideBlankClass: 'adv-swiper-slide-invisible-blank',
            slideActiveClass: 'adv-swiper-slide-active',
            slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
            slideVisibleClass: 'adv-swiper-slide-visible',
            slideDuplicateClass: 'adv-swiper-slide-duplicate',
            slideNextClass: 'adv-swiper-slide-next',
            slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
            slidePrevClass: 'adv-swiper-slide-prev',
            slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
            wrapperClass: 'adv-swiper-wrapper',
            navigation: {
                disabledClass: 'adv-swiper-button-disabled',
                hiddenClass: 'adv-swiper-button-hidden',
                lockClass: 'adv-swiper-button-lock',
                nextEl: '.adv-2-swiper-button-next',
                prevEl: '.adv-2-swiper-button-prev'
            },
            spaceBetween: 0,
            slidesPerView: 'auto'
        });
    }

    render() {
        const brightImageStyle = {
            filter: 'brightness(110%)',
        };


        return (
            <section id="services" className="block" style={{paddingTop:"7.368rem"}}>
                <div className="adv-slider-services">
                    <div className="adv-swiper-container">
                        <div className="adv-swiper-wrapper services-items clearfix">
                            <a title="至奢抗衰护理系列" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">至奢抗衰护理系列</h3>
                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover" style={brightImageStyle}>
                                        <img src="assets/img/placeholder/至奢.png" alt="至奢抗衰护理系列" />
                                    </div>
                                </div>
                            </a>

                            <a title="时空胶囊护理" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside1"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">时空胶囊护理</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/时空.png" alt="Hair Treatments" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="基础护理系列" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside2"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">基础护理系列</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/基础护理图片.png" alt="基础护理系列" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside3"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">眼部抗老护理</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/眼部护理图片.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>


                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside4"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">身体舒缓护理</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/身体舒缓new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside5"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">日式整骨系列</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/3DFacebone_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>


                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside7"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">干细胞治疗</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/干细胞new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside8"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">水光焕颜治疗</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/水光针图片.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside6"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">黄金微针</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/黄金微针.jpg" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside9"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">索夫波</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/softwave_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside10"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">超光子嫩肤</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/alma_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Haircuts for Men" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside11"}>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">7D聚拉提</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">更多信息</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/placeholder/7D_new.png" alt="Haircuts for Men" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                        </div>

                        <div className="adv-2-swiper-button-prev">
                            <span>
                                <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">前</span>
                            </span>
                        </div>

                        <div className="adv-2-swiper-button-next">
                            <span>
                                <span className="btn btn-lg btn-after-dot btn-link border-0 p-0 min-w-auto">后</span>
                            </span>

                        </div>


                    </div>

                </div>

            </section>
        );
    }
}

export default ServicesHome;
