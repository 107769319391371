import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleHome from '../blocks/page-title/PageTitleHome';
import ContactsUs from "../blocks/contacts/ContactUsEn";
import ServicesHome from '../blocks/services/ServicesHomeEn';
import AboutsUs from '../blocks/about/AboutUs';
import News from '../blocks/news/News';
import Newsletter from '../blocks/newsletter/Newsletter';
import PageTitleHomeEN from '../blocks/page-title/PageTitleHomeEN';
import HeaderEn from '../blocks/header/HearderEn';
import ServicesHomeEn from '../blocks/services/ServicesHomeEn';
import ServicesHomeListEn from '../blocks/services/ServicesHomeListEn';
import ContactsUsEn from '../blocks/contacts/ContactUsEn';
import { Link } from 'react-router-dom';


const HomeEN = () => {
    document.body.classList.add('home');
    document.body.classList.add('header-absolute-true');

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Laydeez skin laser clinic</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="light" />

            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHomeEN />


                        <ServicesHomeEn />

                        {/* <AboutsUs /> */}


                        <div style={{ padding: '7.368rem 0', backgroundColor: "#efe6e8", marginTop: "7.368rem" }}>
                            <h2 style={{ textAlign: 'center', paddingBottom: '3.158rem', marginottom: '0' }}>why select us?</h2>
                            <div style={{ fontSize: "24px", padding: "0 7.368rem", lineHeight: "30px", textIndent: "3rem" }}>Laydeez is a clinic specializing in skin management, body care and postpartum recovery, dedicated to providing comprehensive care and support to women. We offer a range of professional skin management treatments, body care and postpartum recovery solutions to help women regain their physical and mental confidence. Our team is composed of experienced professionals and uses advanced technology, equipment and personalized care plans to ensure that each customer gets the best experience and results. Whether you want to improve your skin or restoration!</div>
                        </div>

                        <ServicesHomeListEn/>

                        {/* <News />

                        <Newsletter /> */}
                        <div id="img" className="block spacer p-top-xl">
                            <div>
                                <h3 style={{ color: '#fff', textAlign: "center", marginBottom: '0.985rem' }}>Sydney Branch</h3>
                            </div>
                            <div className="img-no-wrap-1">
                                <div className="img object-fit">
                                    <Link to={'/about-us#xini'}>
                                        <div className="object-fit-cover">
                                            <img src="assets/img/placeholder/大图.png" alt="Beauty Salon" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div id="img" className="block spacer p-top-xl">
                            <h3 style={{ color: '#fff', textAlign: "center" }}>Melbourne Branch</h3>

                            <div className="img-no-wrap-1">
                                <div className="img object-fit">
                                    <Link to={'/about-us#moerben'}>
                                        <div className="object-fit-cover">
                                            <img src="assets/img/placeholder/大图.png" alt="Beauty Salon" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <ContactsUsEn />

                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default HomeEN;