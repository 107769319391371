import React, { Fragment, useEffect} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/HearderEn';
import PageTitleServicesInsideEn2 from '../blocks/page-title/PageTitleServicesInsideEn2'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServicesEn';
import Introduction from '../components/services/Introduction';

const ServicesInsideEn2 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInsideEn2 />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/基础护理图片.png" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}
                                    <Introduction list={["We are committed to providing you with a one-stop skin care experience to help you achieve healthy and glowing skin. Our professional team combines a variety of advanced technologies and high-quality products to provide a variety of care services. Whether you're looking for deep cleansing, hydration, or targeting acne and dull skin, we can create a personalized treatment plan just for you. Here, you can not only enjoy professional services, but also feel relaxed and happy. We look forward to your visit and starting a beautiful journey with you!"]}></Introduction>
                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">Services Details</h2>
                                        </div>
                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Deep Clean Facial</span>
                                                            <span className="list-group-price">Through deep cleansing, the dirt on the surface of the skin and in the pores is removed, leaving the skin glowing and healthy.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Wine Ance Facial</span>
                                                            <span className="list-group-price">Use the antioxidant properties of red wine to effectively inhibit the formation of acne and help restore smoothness to the skin.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Dermalift Facial</span>
                                                            <span className="list-group-price">Through lymphatic detoxification techniques, blood circulation is promoted, toxins are discharged from the body, and the skin is made firmer and more elastic.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Oxygen Facial</span>
                                                            <span className="list-group-price">Deeply hydrates, locks in moisture, and keeps skin moist and smooth all day long.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Cold Repair Facial</span>
                                                            <span className="list-group-price">Through cold conduction technology, it promotes cell repair, improves skin condition, and makes skin more delicate and smooth.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">LED Facial</span>
                                                            <span className="list-group-price">Use light therapy technology to even out skin tone, brighten skin, and reveal a natural and healthy glow.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Aqua Peel Facial</span>
                                                            <span className="list-group-price">Gently exfoliate, remove impurities from pores, and help restore skin to a bright and clear state.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Acne Control Facial</span>
                                                            <span className="list-group-price">Blue light technology targets acne problems, effectively anti-inflammatory and sterilizing, and helps restore balance to the skin.</span>
                                                        </li>
                                                    </ul>

                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">玻尿酸瀑布护理</span>
                                                            <span className="list-group-price">90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">氧气泡泡护理</span>
                                                            <span className="list-group-price">90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Hair styling</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Hair styling for occasion</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Consulting</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Trial hairstyling for occasion</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">All hair color</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Root color</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Highlights</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Hair toning</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInsideEn2;