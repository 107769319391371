import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServicesHomeList extends Component {

    render() {
        const list = [{
            title: "Laser Treatment",
            list1: [{ title: "Lucas Freckle and Mole Removal", link: '/services-insideEn16' },
            { title: "Harmony Laser", link: '/services-insideEn15' },
            { title: "Spectra Laser", link: '/services-insideEn16' }],
            bgImg: "url(assets/img/placeholder/黄金微针.jpg)",
        }, {
            title: "Body Relaxation",
            list1: [{ title: "Lymphatic Detox Massage", link: "/services-insideEn4" },
            { title: "Cupping Therapy", link: "/services-insideEn4" },
            { title: "Moxibustion", link: "/services-insideEn4" },
            { title: "Lipolysis", link: "/services-insideEn4" },
            { title: "Gua Sha", link: "/services-insideEn4" }],
            bgImg: "url(assets/img/placeholder/身体舒缓护理.webp)"
        }, {
            title: "Japanese Orthopedic Series",
            list1: [{ title: "3D", link: "/services-insideEn5" },
            { title: "Facial Orthopedics", link: "/services-insideEn5" },
            { title: "Leg Length Adjustment", link: "/services-insideEn5" },
            { title: "Thoracic Kyphosis Correction", link: "/services-insideEn5" },
            { title: "Rib Flare Correction", link: "/services-insideEn5" },
            { title: "Pubic Bone Correction", link: "/services-insideEn5" },
            { title: "Pelvic Bone Correction", link: "/services-insideEn5" }],
            bgImg: "url(assets/img/placeholder/整骨.jpg)"
        }, {
            title: "Postpartum Recovery",
            list1: [{ title: "Rectus Abdominis Repair", link: "/services-insideEn17" },
            { title: "Pseudo-Hip Width Repair", link: "/services-insideEn17" },
            { title: "Pubic Bone Repair", link: "/services-insideEn17" },
            { title: "Pelvic Bone Repair", link: "/services-insideEn17" }],
            bgImg: "url(assets/img/placeholder/基础护理.png)"
        }];

        const bgBox = {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            filter: 'blur(5px) brightness(0.5)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backdropFilter: 'blur(2px)',
            zIndex: '-1',
            borderRadius: '8px'
        }

        const style = {
            padding: '40px 20px',
            textAlign: 'center',
            borderRadius: '8px',
            height: "100%",
            display: "flex",
            flexDirection: "column",
            color: "rgb(239, 230, 232)"
        }

        return (
            <div className='p-top-xl block spacer' style={{ padding: '0 3rem' }}>
                <h2 style={{ textAlign: 'center', paddingBottom: "3rem" }}>我们的服务</h2>
                <div className='row gutter-width-sm'>


                    <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' style={{ marginBottom: '20px' }}>
                        <div style={{ ...style, position: "relative" }}>
                            <div>
                                <h3 style={{ paddingBottom: '10px', color: '#fff' }}>Skin Management</h3>
                                <div className='row' style={{ justifyContent: "space-around" }}>
                                    <div style={{ flex: 1 }}>
                                        <h4 style={{ color: "rgb(239, 230, 232)" }}>Manual+Equipment</h4>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn2'}>Basic Care Series</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn14'}>Wishpro</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn'} > Luxury Anti-Aging Care Series</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn3'}>Eye Anti-Aging Care</Link>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <h4 style={{ color: "rgb(239, 230, 232)" }}>Large Equipment</h4>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn7'}>Stem Cell Therapy</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn6'}>Golden Microneedle</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn8'}>Hydro Brightness</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn9'}>Sofwave</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn11'}>Juplatten Pro</Link>
                                        <Link style={{ lineHeight: "26px", fontSize: '22px', color: "rgb(239, 230, 232)", display: "block" }} to={'/services-insideEn12'}>10D MPT</Link>
                                    </div>
                                </div>
                                <div style={{ ...bgBox, backgroundImage: "url(assets/img/placeholder/至奢大图.png)" }}></div>

                            </div>


                        </div>

                    </div>
                    {list.map(item => {
                        return (
                            <div className='col-xl-4 col-lg-4 col-md-8 col-sm-12' style={{ marginBottom: '20px' }}>
                                <div style={{ ...style, position: "relative" }}>
                                    <div>
                                        <h3 style={{ paddingBottom: '10px', color: "#fff" }}>{item.title}</h3>
                                        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
                                            {item.list1.map(items => {
                                                return (
                                                    <Link style={{ lineHeight: "28px", fontSize: '24px', color: "rgb(239, 230, 232)" }} to={items.link}>{items.title}</Link>
                                                )
                                            })}
                                        </div>
                                        <div style={{ ...bgBox, backgroundImage: item.bgImg }}></div>

                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default ServicesHomeList;