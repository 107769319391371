import React from 'react';

const Maps = () => {
    return (
        <div style={{ overflow: 'hidden', padding: '0', border: 'none', margin: '0 auto', display: 'block', maxWidth: '2200px', maxHeight: '450px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8363644453566!2d145.1113020953142!3d-37.81730166197371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad640b7f8d83b2b%3A0xf74603720fa1c705!2sRegus%20-%20Melbourne%2C%20Box%20Hill!5e0!3m2!1szh-TW!2shk!4v1730299288099!5m2!1szh-TW!2shk"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    );
};

export default Maps;
