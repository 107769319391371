import React from 'react';

const PageTitleServicesInsideEn4 = () => {
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h1 className="large">Body treatment</h1>
            </div>
        </div>
    );
};

export default PageTitleServicesInsideEn4;
