import React from 'react';

const Maps = () => {
    return (
        <div style={{ overflow: 'hidden', padding: '0', border: 'none', margin: '0 auto', display: 'block', maxWidth: '2200px', maxHeight: '450px' }}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.74079508775!2d151.10213387540028!3d-33.87057041915007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bad02e09e63f%3A0x4de445ac2787f9ca!2sshop%201%2F19%20Burwood%20Rd%2C%20Burwood%20NSW%202134!5e0!3m2!1szh-CN!2sau!4v1709335325646!5m2!1szh-CN!2sau"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    );
};

export default Maps;
