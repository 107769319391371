import React from 'react';

const AboutServices = () => {
    return (
        <div id="services" className="block spacer p-top-xl">
            <div className="text-center">
                <a href={ process.env.PUBLIC_URL + "/services" } className="btn btn-outline-secondary">更多了解我们的服务</a>
                <a href={ process.env.PUBLIC_URL + "/contacts" } style={{marginLeft:'20px'}} className="btn btn-outline-secondary">加入我们</a>
            </div>
        </div>
    );
};

export default AboutServices;
