import React, { Fragment, useEffect} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/HearderEn';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInsideEn12'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServicesEn';
import HeaderEn from '../blocks/header/HearderEn';
import Introduction from '../components/services/Introduction';
import WhyComponent from '../components/services/WhyComponent';
const ServicesInsideEn11 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const whyList = [
        {
            title: 'APPLICATION',
            content: "Face & Body"
        },
        {
            title: 'MICRO & MACRO FOCUSED',
            content: "Technology"
        },
        {
            title: 'NON-INVASIVE PROCEDURE',
            content: "Safety"
        },
        {
            title: 'EFFECTIVE & LESS PAIN',
            content: "Treatment"
        },
        {
            title: 'MULTI-FUNCTIONAL & MULTI-DEPTH',
            content: "Cartridges"
        },
        {
            title: 'HIGH PEAK POWER',
            content: "Dual Engine"
        },
        {
            title: 'FASTER & PRECISE SHOT APPLICATION',
            content: "Transducers"
        },
    ]
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover" style={{ display: "flex", justifyContent: "space-around", columnGap: "1rem" }}>
                                                    <div style={{ maxHeight: '700px', flex: '1' }}> <img src="assets/img/placeholder/MPT治疗.jpg" alt="Hair Treatments" /></div>
                                                    <div style={{ maxHeight: '700px', flex: '1' }}><img src="assets/img/placeholder/MPT治疗1.jpg" alt="Hair Treatments" /></div>
                                                    <div style={{ maxHeight: '700px', flex: '1' }}><img src="assets/img/placeholder/MPT治疗2.jpg" alt="Hair Treatments" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}

                                    <Introduction list={['Non-invasive facial lifting, skin tightening, body contouring & Macro & Micro Focused Ultrasound Thermal Coagulation Point quality (TCP)','Ultraformer MPT are ninvasive facial lifting, skin tightening, body contouring & Macro & Micro Focused Ultrasound Thermal Coagulation Point quality (TCP). Micropulse technology refines the existing thermal coagulation point by 25 times, delivering energy to the treatment area to achieve intensive treatment. 10D works with many plants to combat sagging skin, wrinkles and aging. Considered the latest HIFU technology, it can prolong beauty and youth.']}></Introduction>

                                    <WhyComponent list={whyList} title='Why MPT？' />

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">Range of Applications</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Nasolabial folds</span>
                                                            {/* <span className="list-group-price">120 mins</span> */}
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Eye Bag and Wrinkles</span>
                                                            {/* <span className="list-group-price">120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Forehead Wrinkles</span>
                                                            {/* <span className="list-group-price">120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Double Chin</span>
                                                            {/* <span className="list-group-price">120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Lower Face</span>
                                                            {/* <span className="list-group-price">120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Full Face</span>
                                                            {/* <span className="list-group-price">120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Full Face to Neck</span>
                                                            {/* <span className="list-group-price">120 mins</span> */}
                                                        </li>


                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">玻尿酸瀑布护理</span>
                                                            <span className="list-group-price">90-120 mins</span>
                                                        </li> */}


                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">玻尿酸瀑布护理</span>
                                                            <span className="list-group-price">90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">氧气泡泡护理</span>
                                                            <span className="list-group-price">90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Hair styling</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Hair styling for occasion</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Consulting</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Trial hairstyling for occasion</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">All hair color</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Root color</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Highlights</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Hair toning</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInsideEn11;