import React from 'react';

const Introduction = ({ list }) => {
    // 确保 list 不是 null 或 undefined，并且是一个数组
    if (!Array.isArray(list) || list.length === 0) {
      return (
        <div style={{ fontSize: "28px", lineHeight: "36px", textIndent: "3rem", color: "#000" }} className='p-top-xl spacer block' >
          <p>No projects available.</p>
        </div>
      );
    }
  
    return (
      <div className='p-top-xl spacer block' >
        {list.map((project, index) => (
          <div style={{ fontSize: "28px", lineHeight: "36px", textIndent: "3rem", color: "#000",padding:'1rem'}} key={index}>{project}</div>
        ))}
      </div>
    );
  };
  
export default Introduction
