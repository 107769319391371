import React from 'react';

const HeaderLogo = ( { logoColor } ) => {
    return (
        <div className="header-logo">
            <a className="logo logo-secondary transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL + "/" }>
                              <img src="assets/img/logo/logo.svg" alt="Logo" style={{ width: '100px', height: 'auto' }} />


            </a>
        </div>
    );
};

export default HeaderLogo;
