import React from 'react';

const Contacts = () => {
    // 定义统一的样式对象
    const itemStyle = {
        marginLeft: '5rem', 
    };

    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a href="mailto:company@domain.com">Laydeez_skin</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>微信</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>shop 1 / 19 Burwood Road Burwood NSW </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>地址</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a href="tel:005053255350539">0272089381</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>电话</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                           17RileyLn,BurwoodNSW,2134
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6>停车地址</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;

