import React from 'react';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
import SearchModal from '../../components/modal/SearchModal';
import MenuModal from '../../components/modal/MenuModal';
import Actions from "../actions/Actions";
import { Link } from 'react-router-dom';

const Header = ( { logoColor, position} ) => {
    return (
        <header id="header" className="site-header"  style={{position: position?position:""}}>
            <div className="wrapper">
                <div className="header-content d-flex justify-content-between">
                    <div className="header-left align-self-center">
                        <div className="links">
                            <div className="links-items">
                                <div className="links-item">
                                    <a style={{fontSize:'20px'}} href={ process.env.PUBLIC_URL + "/" } className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">首页</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-center align-self-center">
                        <HeaderLogo logoColor={ logoColor } />
                    </div>

                    <div className="header-right d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <SearchModal />
                            <Link style={{fontSize:'20px'}} to={'services'}>服务</Link>
                            <Link style={{fontSize:'20px'}} to={'about-us'}>关于我们</Link>
                            <Link style={{fontSize:'20px'}} to={'contacts'}>加入我们</Link>
                            {/* <MenuModal /> */}
                 
                        </div>

                        <Actions />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
