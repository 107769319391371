import React from 'react';

const PageTitleServicesInside10 = () => {
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h1 className="large">超光子嫩肤</h1>
            </div>
        </div>
    );
};

export default PageTitleServicesInside10;