import React, { useState, useEffect } from 'react';

const PageTitleHomeEN = () => {
    const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
    const backgrounds = [
        'url(assets/img/placeholder/悉尼店面1.jpg)',
        'url(assets/img/placeholder/悉尼店面2.jpg)',
        'url(assets/img/placeholder/悉尼店面3.jpg)',
        'url(assets/img/placeholder/悉尼店面4.jpg)',
        'url(assets/img/placeholder/悉尼证书.jpg)',
        'url(assets/img/placeholder/悉尼产品.jpg)',
        'url(assets/img/placeholder/悉尼治疗室.jpg)',
        'url(assets/img/placeholder/悉尼治疗室2.jpg)',
    ];


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentBackgroundIndex((currentBackgroundIndex + 1) % backgrounds.length);
        }, 4000); // Change image every 3 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [currentBackgroundIndex, backgrounds.length]);

    const sectionStyle = {
        backgroundImage: backgrounds[currentBackgroundIndex],
        transition: 'background-image 3s ease-in-out', // Smooth transition between images
    };
    return (
        <section id="page-title" className="block with-img" style={sectionStyle}>
            <div className="wrapper text-center d-flex">
                <div className="align-self-center w-100">


                    <div className="title">
                        <h1 className="large">Laydeez skin laser clinic</h1>
                    </div>



                    {/* <div className="languages lang-position" style={{ position: 'absolute', top: '75px', left: '150px' }}>
                        <ul className="nav">
                            <li className="lang-item current-lang">
                                <a title="中文" href="/">CN</a>
                            </li>
                        </ul>
                    </div> */}

                    {/* <div className="header-left align-self-center" style={{ position: 'absolute', top: '153px', left: '40px' }}>
                        <div className="links">
                            <div className="links-items">
                                <div className="links-item">
                                    <a href={ process.env.PUBLIC_URL + "/" } className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">中文/英文</a>
                                </div>
                            </div>
                        </div>
                    </div> */}




                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHomeEN;