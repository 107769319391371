import React from 'react';

const PageTitleServicesInsideCom = ({str = ''}) => {
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                 <h1 className="large">{str}</h1>
            </div>
        </div>
    );
};

export default PageTitleServicesInsideCom;