import React from 'react';

const PageTitleServicesInsideEn1 = () => {
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h1 className="large">Wishpro instant facial treatment with serum capsule</h1>
            </div>
        </div>
    );
};

export default PageTitleServicesInsideEn1;
