import React from 'react';

const PageTitleServicesInside11 = () => {
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h1 className="large">7D聚拉提</h1>
            </div>
        </div>
    );
};

export default PageTitleServicesInside11;